import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

function StructuredService(props) {
  const { name } = props;
  const data = {
    '@context': 'https://schema.org/',
    '@type': 'Service',
    areaServed: [
      'US',
      'GB',
      'FR',
      'DK',
      'SE',
      'ES',
      'NO',
      'DE',
      'FI',
      'PL',
      'IT',
      'RO',
      'GR',
      'BG',
      'IS',
      'HU',
      'PT',
      'AT',
      'CZ',
      'RS',
      'IE',
      'LV',
      'LT',
      'HR',
      'BA',
      'SK',
      'SI',
      'EE',
      'CH',
      'NL',
      'MD',
      'BE',
      'SI',
      'ME',
      'CY',
      'LU',
      'AD',
      'MT',
      'MT',
    ],
    serviceType: [name],
  };

  return (
    <Helmet>
      <script type="application/ld+json">{`${JSON.stringify(data)}`}</script>
    </Helmet>
  );
}

StructuredService.propTypes = {
  name: PropTypes.string.isRequired,
};

export default StructuredService;
