import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import useSiteMetadata from '../../hooks/useSiteMetadata';

function StructuredAggregateRating(props) {
  const {
    description,
    makesOffer,
    knowsAbout,
    name,
    ratingValue,
    bestRating,
    ratingCount,
  } = props;

  const { url } = useSiteMetadata();
  const logUrl = `${url}/logo.png`;

  const data = {
    '@context': 'https://schema.org/',
    '@type': 'AggregateRating',
    itemReviewed: {
      '@type': 'Organization',
      brand: 'SpySERP',
      logo: logUrl,
      description,
      areaServed: [
        'US',
        'GB',
        'FR',
        'DK',
        'SE',
        'ES',
        'NO',
        'DE',
        'FI',
        'PL',
        'IT',
        'RO',
        'GR',
        'BG',
        'IS',
        'HU',
        'PT',
        'AT',
        'CZ',
        'RS',
        'IE',
        'LV',
        'LT',
        'HR',
        'BA',
        'SK',
        'SI',
        'EE',
        'CH',
        'NL',
        'MD',
        'BE',
        'SI',
        'ME',
        'CY',
        'LU',
        'AD',
        'MT',
        'MT',
      ],
      makesOffer,
      knowsAbout,
      legalName: 'SpySERP Ltd',
      alternateName: 'SpySERP',
      url,
      sameAs: [
        'https://twitter.com/spyserp',
        'https://www.facebook.com/spyserp.global/',
        'https://telegram.me/joinchat/B52bK0Fe78S_qbDEPuJmYA',
        'https://linkedin.com/company/spyserp',
      ],
      email: 'support@spyserp.com',
      address: '27 Old Gloucester Street London WC1N 3AX United Kingdom',
      name,
      image: logUrl,
    },
    ratingValue,
    bestRating,
    ratingCount,
  };

  return (
    <Helmet>
      <script type="application/ld+json">{`${JSON.stringify(data)}`}</script>
    </Helmet>
  );
}

StructuredAggregateRating.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  makesOffer: PropTypes.string.isRequired,
  knowsAbout: PropTypes.string.isRequired,
  ratingValue: PropTypes.number.isRequired,
  bestRating: PropTypes.number.isRequired,
  ratingCount: PropTypes.number.isRequired,
};

export default StructuredAggregateRating;
