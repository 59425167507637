import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import DollarIcon from '../assets/icons/font-awesome/solid/dollar-sign.svg';
import RubleIcon from '../assets/icons/font-awesome/solid/ruble-sign.svg';
import CheckIcon from '../assets/icons/font-awesome/solid/check.svg';
import TimesIcon from '../assets/icons/font-awesome/solid/times.svg';
import GetStartedButton from './GetStartedButton';
import IntercomButton from './Intercom/IntercomButton';
import ContentSection from './ContentSection';
import useSpyserpAppInfo from '../hooks/useSpyserpAppInfo';

const OveragesPopoverTrigger = (props) => {
  const { children } = props;
  const { t } = useTranslation('Pricing');
  const spySerpAppInfo = useSpyserpAppInfo();
  const { packages } = spySerpAppInfo;

  const overlay = (
    <Popover id="overages-popover">
      <Popover.Title as="h3">{t('overagesPopover.title')}</Popover.Title>
      <Popover.Content>
        <p className="gr-text-12">{t('overagesPopover.description')}</p>
        <p className="gr-text-12">{t('overagesPopover.subtitle')}</p>

        <table className="table table-bordered table-sm">
          <thead className="thead-light">
            <tr>
              <th style={{ width: 120 }}>
                {t('overagesPopover.tableHeader1')}
              </th>
              <th className="text-right">
                {t('overagesPopover.tableHeader2')}
              </th>
              <th className="text-right">
                {t('overagesPopover.tableHeader3')}
              </th>
            </tr>
          </thead>

          <tbody>
            {packages.map((item) => (
              <tr key={`package-id-${item.id}`}>
                <td>{item.name}</td>
                <td className="text-right">
                  {item.limits.checks.toLocaleString()}
                </td>
                <td className="text-right">${item.checkPrice}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={overlay}
      rootClose
    >
      <span className="btn-link ml-1" style={{ cursor: 'pointer' }}>
        {children}
      </span>
    </OverlayTrigger>
  );
};

const Pricing = (props) => {
  const { bg = 2 } = props;
  const spySerpAppInfo = useSpyserpAppInfo();

  const [selectedPackageData, setSelectedPackageData] = useState(
    spySerpAppInfo?.selectedPackageData
  );
  const [selectedPackage, setSelectedPackage] = useState(
    spySerpAppInfo?.selectedPackage
  );
  const [isMonthly, setIsMonthly] = useState(true);

  const { t } = useTranslation('Pricing');

  const handleChange = (event) => {
    const packageId = Number(event.target.value);
    const selectedPackageData = spySerpAppInfo?.packages?.find(
      (item) => item.id === packageId
    );

    const selectedPackage = {
      value: selectedPackageData.id,
      label: selectedPackageData.name,
    };

    setSelectedPackageData(selectedPackageData);
    setSelectedPackage(selectedPackage);
  };

  let isUsd = spySerpAppInfo.currency !== 'rub';

  const selectedPackageMonthlyPrice = isMonthly
    ? selectedPackageData?.amountMonthlyPayMonthly
    : selectedPackageData?.amountMonthlyPayAnnually;

  const yearlyDiscount = Math.round(
    selectedPackageData?.amountAnnuallyPayMonthly -
      selectedPackageData?.amountAnnuallyPayAnnually
  );

  const selectedPackageChecks =
    selectedPackageData?.limits?.checks?.toLocaleString();
  const selectedPackageCheckPrice = selectedPackageData?.checkPrice;

  const freePackageFeatures = [
    t('freePackage.feature1'),
    t('freePackage.feature2'),
    t('freePackage.feature3'),
    t('freePackage.feature4'),
    t('freePackage.feature5'),
    t('freePackage.feature6'),
    t('freePackage.feature7'),
  ];

  const selectedPackageFeatures = [
    t('selectedPackage.feature1', {
      limits: { checks: selectedPackageData.limits.checks.toLocaleString() },
    }),
    t('selectedPackage.feature2'),
    t('selectedPackage.feature3'),
    t('selectedPackage.feature4', selectedPackageData),
    t('selectedPackage.feature5'),
  ];

  return (
    <ContentSection id="pricing" title={t('title')} background={bg}>
      <div className="text-center">
        <div
          className="mb-13 d-inline-flex position-relative"
          id="pricing-dynamic-deck--head"
        >
          <span className="period month gr-text-8 gr-text-color ">
            {t('periodToggle.label1')}
          </span>

          <div
            className={classNames('btn-toggle mx-3 price-deck-trigger', {
              active: !isMonthly,
            })}
            onClick={(e) => {
              e.preventDefault();
              setIsMonthly(!isMonthly);
            }}
          >
            <span className="round" />
          </div>

          <span className="period year gr-text-8 gr-text-color">
            {t('periodToggle.label2')}
          </span>

          <span className="badge gr-badge text-primary gr-text-12 gr-bg-blue-opacity-1 rounded-pill text-uppercase ml-1 ml-md-5">
            {t('yearlyDiscountPercent')}
          </span>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-10 mb-9">
            <div className="pricing-card d-flex flex-column justify-content-between h-100 bg-white gr-hover-shadow-1 border text-left pt-9 pb-9 pr-9 pr-xl-10 pl-9 pl-xl-10 bg-white rounded-10">
              <div>
                <span className="small-title gr-text-12 text-primary font-weight-bold text-uppercase">
                  {t('freePackage.title')}
                </span>

                <p className="small" style={{ minHeight: 39 }}>
                  {t('freePackage.description')}
                </p>

                <div
                  className="text-blackish-blue gr-text-7 font-weight-bold mt-5 pt-9"
                  style={{ minHeight: 100 }}
                >
                  {t('freePackage.price')}
                </div>

                <ul className="card-list list-style-check pl-0 mt-7 mt-lg-11">
                  {freePackageFeatures.map((feature, index) => (
                    <li
                      className="gr-text-9"
                      key={`free-package-feature-${index}`}
                    >
                      <span
                        className={`fa-svg-icon svg-color-${
                          index > 3 ? 'disabled' : 'success'
                        } mr-5`}
                      >
                        {index > 3 ? <TimesIcon /> : <CheckIcon />}
                      </span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="text-center">
                <GetStartedButton
                  text={t('freePackage.ctaText')}
                  hideArrowIcon
                  isLink
                />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-10 mb-9">
            <div className="pricing-card d-flex flex-column justify-content-between h-100 bg-white gr-hover-shadow-1 border border-primary text-left pt-9 pb-9 pr-9 pr-xl-10 pl-9 pl-xl-10 bg-white rounded-10">
              <div>
                <label
                  htmlFor="react-select-package-options-input"
                  className="small-title gr-text-12 text-primary font-weight-bold text-uppercase mb-0"
                >
                  {t('selectedPackage.title', selectedPackageData)}
                </label>

                <p className="small" style={{ minHeight: 39 }}>
                  {t('selectedPackage.description')}
                </p>

                <div className="d-flex align-items-end">
                  <span className="currency mr-2 gr-text-6 font-weight-bold line-spacing-none text-blackish-blue">
                    {isUsd && (
                      <span className="fa-svg-icon">
                        <DollarIcon />
                      </span>
                    )}

                    {!isUsd && (
                      <span className="fa-svg-icon">
                        <RubleIcon />
                      </span>
                    )}
                  </span>

                  <Trans t={t} i18nKey="selectedPackage.monthlyPrice">
                    <span className="price-value gr-text-2 font-weight-bold line-spacing-none mb-0 text-blackish-blue">
                      {{ selectedPackageMonthlyPrice }}
                    </span>
                    <span className="mx-2 gr-text-9 text-blackish-blue">/</span>
                    <span className="per gr-text-9 text-blackish-blue">
                      month
                    </span>
                  </Trans>
                </div>

                <span
                  className="price-bottom-text gr-text-11 mb-5 text-blackish-blue gr-opacity-7 d-inline-flex"
                  style={{ minHeight: 52 }}
                >
                  {isMonthly && (
                    <span>{t('selectedPackage.monthlyDescription')}</span>
                  )}

                  {!isMonthly && (
                    <div>
                      {t('selectedPackage.yearlyPrice', {
                        price: selectedPackageData.amountAnnuallyPayAnnually,
                        currencySymbol: isUsd ? '$' : '₽',
                      })}

                      <div className="text-danger">
                        {t('selectedPackage.yearlyDiscount', {
                          discount: yearlyDiscount,
                          currencySymbol: isUsd ? '$' : '₽',
                        })}
                      </div>
                    </div>
                  )}
                </span>

                <select
                  className="form-control"
                  value={selectedPackage.value}
                  onChange={handleChange}
                >
                  {spySerpAppInfo?.packagesForSelect.map((item) => (
                    <option
                      value={item.value}
                      key={`package-option-${item.value}`}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>

                <p className="small mt-5 text-center">
                  <Trans t={t} i18nKey="selectedPackage.overage">
                    <span className="d-block">
                      {{ selectedPackageChecks }} Keywords Checkups / month
                    </span>
                    <OveragesPopoverTrigger>Overages:</OveragesPopoverTrigger>
                    <span className="ml-1">
                      ${{ selectedPackageCheckPrice }} per 1000
                    </span>
                  </Trans>
                </p>

                <ul className="card-list list-style-check pl-0 mt-7 mt-lg-11">
                  {selectedPackageFeatures.map((feature, index) => (
                    <li
                      className="gr-text-9"
                      key={`selected-package-feature-${index}`}
                    >
                      <span className="fa-svg-icon svg-color-success mr-5">
                        <CheckIcon />
                      </span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>

              <GetStartedButton
                text={t('selectedPackage.ctaText')}
                hideArrowIcon
                cardRequired
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-10 mb-9">
            <div
              className="pricing-card d-flex flex-column justify-content-between h-100
                   gr-hover-shadow-1 border text-left pt-9 pb-9 pr-9 pr-xl-13  pl-9 pl-xl-13 bg-white rounded-10"
            >
              <div>
                <span className="small-title gr-text-12 text-primary font-weight-bold text-uppercase">
                  {t('enterprisePackage.title')}
                </span>

                <p className="small" style={{ minHeight: 39 }}>
                  {t('enterprisePackage.description')}
                </p>

                <div
                  className="text-blackish-blue gr-text-7 font-weight-bold mt-5 mb-10 pt-8"
                  style={{ minHeight: 100 }}
                >
                  {t('enterprisePackage.price')}
                </div>

                <div>{t('enterprisePackage.feature1')}</div>
              </div>

              <div className="text-center">
                <IntercomButton text={t('enterprisePackage.ctaText')} isLink />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentSection>
  );
};
export default Pricing;
