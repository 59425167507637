import { useStaticQuery, graphql } from 'gatsby';

function useSpyserpAppInfo() {
  const query = graphql`
    query spySerpAppInfoQuery {
      spySerpAppInfo {
        packages {
          id
          name
          checkPrice
          amountAnnuallyPayAnnually
          amountAnnuallyPayMonthly
          amountMonthlyPayAnnually
          amountMonthlyPayMonthly
          limits {
            checks
            domains
            project
            se
          }
        }
        selectedPackage {
          value
          label
        }
        selectedPackageData {
          id
          name
          checkPrice
          amountAnnuallyPayAnnually
          amountAnnuallyPayMonthly
          amountMonthlyPayAnnually
          amountMonthlyPayMonthly
          limits {
            checks
            domains
            project
            se
          }
        }
        packagesForSelect {
          label
          value
        }
        freePackageData {
          id
          name
          checkPrice
          limits {
            checks
            domains
            project
            se
          }
        }
      }
    }
  `;

  const packagesData = useStaticQuery(query);
  const { spySerpAppInfo } = packagesData;
  return spySerpAppInfo;
}

export default useSpyserpAppInfo;
